import React from "react";
import { createContext, useContext, useState, useCallback } from "react";

import api from "../services/api.services";

import {
  BuyOrder,
  NewBuyOrderRequest,
  BuyOrderContextProps,
  RequestParams,
  CompareNFParams,
  ValidationsData,
  FinishApproveProps,
} from "../@types/buyOrder";

import { formatValue } from "../utils/format/formatValue";

import { useAuth } from "./auth";
import { toast } from "react-toastify";
import { getErrorMessage } from "../utils/validations/getErrorMessage";
import { queryClient } from "../services/queryClient";

export enum OcStatus {
  MAINTENER = 1,
  APROVE = 2,
  APROVED = 3,
  REPROVED = 4,
  BILLING_PROCESS = 5,
  CANCELED = 6,
  PARTIAL_BILLING_PROCESS = 7,
  APROVE_COAST_CENTER = 8,
}

const BuyOrderContext = createContext({} as BuyOrderContextProps);

const isEmptyDate = (date: string) => {
  return !date?.replace("/", "").trim();
};

export const BuyOrderProvider: React.FC = ({ children }) => {
  const { userData } = useAuth();

  const [selectedOrder, setSelectedOrder] = useState<BuyOrder>(
    sessionStorage.getItem("SELECTED_BRANCH") ? JSON.parse(sessionStorage.getItem("SELECTED_ORDEM") || "{}") || undefined : ({} as BuyOrder)
  );

  const getBuyOrder = useCallback(
    async (id: string): Promise<BuyOrder | null> => {
      let requestParams = {} as RequestParams;

      if (!userData.permissions?.includes("FINANC")) {
        if (userData.approver) {
          requestParams.aprovador = userData.id;
        } else {
          requestParams.solicitante = userData.id;
        }
      } else {
        requestParams.financeiro = userData.id;
      }

      try {
        const response = await api.get("/ordemcompra", {
          params: {
            ...requestParams,
            id: id,
            branch: userData.selectedBranch?.id as string,
          },
        });

        if (!!response.data.data) {
          const request = response.data.data.ordem[0];

          return {
            ...request,
            data_aprovacao: isEmptyDate(request.data_aprovacaoacao) ? null : new Date(request.data_aprovacaoacao),
            data_aprovacao2: isEmptyDate(request.data_aprovacaoacao2) ? null : new Date(request.data_aprovacaoacao2),
            data_aprovacao_divergencia: isEmptyDate(request.data_aprovacao_divergencia)
              ? null
              : new Date(request.data_aprovacao_divergencia),
            data_aprovacao_financeiro: isEmptyDate(request.data_aprovacaoacao_financeiro)
              ? null
              : new Date(request.data_aprovacaoacao_financeiro),
            data_emissao: new Date(request.data_emissao),
            data_entrega: new Date(request.data_entrega),
            total: formatValue(request.total),
          };
        } else {
          toast.error(response.data.status.descricao);
          return null;
        }
      } catch (error: any) {
        toast.error(getErrorMessage(error));
        return null;
      }
    },
    [userData]
  );

  const addNewOrder = useCallback(
    async (order: Omit<NewBuyOrderRequest, "id">): Promise<string> => {
      const newOrder = {
        id: "",
        operacao: "I",
        ...order,
      };

      try {
        const response = await api.post(
          "/ordemcompra",
          {
            ordemcompra: newOrder,
          },
          {
            params: { branch: userData.selectedBranch?.id as string },
          }
        );

        if (response.data.status.status !== 200) {
          throw new Error(response.data.status.descricao);
        }

        const { id_OC } = response.data.data;

        queryClient.invalidateQueries({ queryKey: ["requests"] });

        return id_OC;
      } catch (error: any) {
        console.log(error);
        throw error;
      }
    },
    [userData]
  );

  const removeOrder = useCallback(
    async (order: Omit<BuyOrder, "products">): Promise<void> => {
      try {
        await api.post(
          "/ordemcompra",
          {
            ordemcompra: {
              id: order.id,
              operacao: "E",
            },
          },
          {
            params: { branch: userData.selectedBranch?.id || "" },
          }
        );

        queryClient.invalidateQueries({ queryKey: ["requests"] });
      } catch (error: any) {
        console.log(error);
        throw new Error(error.message);
      }
    },
    [userData]
  );

  const updateOrder = useCallback(
    async (order: NewBuyOrderRequest, type: "cost-center" | "buy-order"): Promise<void> => {
      if (type !== "cost-center" && type !== "buy-order") {
        throw new Error("Tipo invalido para atualizar a solicitação");
      }
      const updatedOrder = {
        operacao: type === "cost-center" ? "C" : "A",
        ...order,
      };

      try {
        const response = await api.post(
          "/ordemcompra",
          { ordemcompra: updatedOrder },
          {
            params: { branch: userData.selectedBranch?.id || "" },
          }
        );

        if (response.data.status.status !== 200) {
          throw new Error(response.data.status.descricao);
        }

        queryClient.invalidateQueries({ queryKey: ["requests"] });
      } catch (error: any) {
        console.log(error);
        throw new Error(error.message);
      }
    },
    [userData]
  );

  const selectAnOrder = useCallback((order: BuyOrder): void => {
    setSelectedOrder(order);
    sessionStorage.setItem("SELECTED_ORDEM", JSON.stringify(order));
  }, []);

  const aproveOrder = useCallback(
    async (id: string, justify?: string): Promise<string> => {
      try {
        const response = await api.post(
          "/aprovacao",
          {
            ordem: {
              tipo_aprov: "OC",
              numero: id,
              aprovado: "A",
              justificativa: justify || "",
              id_usuario: userData.id,
            },
          },
          {
            params: { branch: userData.selectedBranch?.id || "" },
          }
        );

        if (response.data.status.status !== 200) {
          throw new Error(response.data.status.descricao);
        }

        queryClient.invalidateQueries({ queryKey: ["requests"] });

        return response.data.status.descricao;
      } catch (error: any) {
        throw new Error(error.message);
      }
    },
    [userData]
  );

  const finishOC = async(idOc: string, motive: string) => {
    try {
        const response = await api.post("/encerra_ordemcompra", {
          ordem: {
            numero: idOc,
            usuario_encerra: userData.id,
            motivo_encerra: motive
          },
        },{
          params: { branch: userData.selectedBranch?.id || "" },
        })

        if (response.data.status.status !== 200) {
          throw new Error(response.data.status.descricao);
        }

        queryClient.invalidateQueries({ queryKey: ["requests"] });

        toast.success("OC encerrada com sucesso")
    } catch (error: any) {
        console.log(error)
        toast.error(error.message || "Falha ao encerrar OC")
    }
}

  const refuseOrder = useCallback(
    async (id: string, justify: string): Promise<string> => {
      try {
        const response = await api.post(
          "/aprovacao",
          {
            ordem: {
              tipo_aprov: "OC",
              numero: id,
              aprovado: "R",
              justificativa: justify,
              id_usuario: userData.id,
            },
          },
          {
            params: { branch: userData.selectedBranch?.id || "" },
          }
        );

        queryClient.invalidateQueries({ queryKey: ["requests"] });

        return response.data.status.descricao;
      } catch (error: any) {
        throw new Error(error.message);
      }
    },
    [userData]
  );

  const implementOC = useCallback(
    async (documentId: string, aproverId: string): Promise<void> => {
      try {
        const response = await api.post(
          "/efetivar",
          {
            ordem: {
              numero: documentId,
              aprovador: aproverId,
            },
          },
          {
            params: { branch: userData.selectedBranch?.id || "" },
          }
        );

        if (response.data.status?.status !== 200 && response.data.status?.status !== 201) {
          throw new Error(response.data.status?.descricao || "Falha ao realizar efetivação");
        }

        queryClient.invalidateQueries({ queryKey: ["requests"] });
      } catch (error: any) {
        throw new Error(error.message);
      }
    },
    [userData]
  );

  const compareNFxOC = useCallback(
    async (data: Omit<CompareNFParams, "status">): Promise<ValidationsData> => {
      try {
        const response = await api.post("/divergOCxNF", data, {
          params: { branch: userData.selectedBranch?.id || "" },
        });

        if (!response.data.data) {
          throw new Error(response.data.status.descricao || "Não foi possível realizar as validações");
        }

        return response.data.data as ValidationsData;
      } catch (error: any) {
        console.log(error);
        throw error;
      }
    },
    [userData]
  );

  const finalizeCheckNFxOC = useCallback(
    async (data: CompareNFParams): Promise<string> => {
      try {
        const response = await api.put("/divergOCxNF", data, {
          params: { branch: userData.selectedBranch?.id || "" },
        });

        if (response.data.status?.status !== 200) {
          throw new Error(response.data.status?.descricao);
        }

        queryClient.invalidateQueries({ queryKey: ["requests"] });

        return response.data.status?.descricao;
      } catch (error: any) {
        console.log(error);
        throw new Error("Não foi possível realizar as validações");
      }
    },
    [userData]
  );

  const aproveDocuments = useCallback(
    async ({ documents, type }: FinishApproveProps) => {
      try {
        await api.post(
          "/aprovanf",
          {
            aprovador: userData.id,
            operacao: type,
            oc: selectedOrder.id,
            documentos: documents,
          },
          {
            params: {
              branch: userData.selectedBranch?.id,
            },
          }
        );

        queryClient.invalidateQueries({ queryKey: ["requests"] });
      } catch (error: any) {
        console.log(error);
        throw error;
      }
    },
    [selectedOrder, userData]
  );

  const startAccountRoutine = useCallback(
    async (id: string): Promise<void> => {
      try {
        await api.post(
          "/atualOCxFIN",
          {
            ordem: {
              numero: id,
              idusrfin: userData.id,
            },
          },
          {
            params: { branch: userData.selectedBranch?.id },
          }
        );

        queryClient.invalidateQueries({ queryKey: ["requests"] });
      } catch (error) {
        throw error;
      }
    },
    [userData]
  );

  return (
    <BuyOrderContext.Provider
      value={{
        selectedOrder,
        aproveDocuments,
        selectAnOrder,
        getBuyOrder,
        addNewOrder,
        removeOrder,
        updateOrder,
        aproveOrder,
        refuseOrder,
        implementOC,
        compareNFxOC,
        finalizeCheckNFxOC,
        startAccountRoutine,
        finishOC
      }}
    >
      {children}
    </BuyOrderContext.Provider>
  );
};

export const useBuyOrder = () => {
  const context = useContext(BuyOrderContext);

  if (!context) {
    throw new Error("The hook useBuyOrder must be used inside a BuyOrderProvider");
  }

  return context;
};
