import React from "react";
import { useCallback, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { AnyObject } from "yup/lib/types";
import * as Yup from "yup";

import api from "../../../services/api.services";
import { Supplier } from "../../../@types/supplier";

import { useSupplier } from "../../../hooks/supplier";
import { useAuth } from "../../../hooks/auth";
import { useBilling } from "../../../hooks/billing";

import { getErrorMessage } from "../../../utils/validations/getErrorMessage";
import { validadeCNPJ, validadeCPF } from "../../../utils/validations/validateDocuments";
import { removeSymbolsOnString } from "../../../utils/format/removeSymbolsOnString";
import getValidationErros from "../../../utils/validations/getValidationErros";
import { SupplierFormDefaultValue } from "../../../data/formValues";

import { Button } from "../../../components/Buttons/Button";
import FormInput, { FormInputRef } from "../../../components/Form/FormInput";
import FormSelect, { FormSelectRef } from "../../../components/Form/FormSelect";
import FormAutoComplete, { FormAutoCompleteRef } from "../../../components/Form/FormAutoComplete";
import FormDatePicker from "../../../components/Form/FormDataPicker"

import { Grid } from "@mui/material";

import { Container, FormActions } from "./style";
import { toast } from "react-toastify";
import { FormCard } from "../../../components/Form/FormCard";
import FormInputMask from "../../../components/Form/FormInputMask";
import { convertDateToEnglishPattern } from "../../../utils/format/convertDateToEnglishPattern";
import { getDateEnglish } from "../../../utils/format/getData";

interface FormData {
  nome: string;
  fantasia: string;
  email: string;
  cnpj: string;
  inscricao: string;
  ddd: string;
  telefone: string;
  contato: string;
  integra: string;
  chave: string;
  tipo_chave: string;
  banco: string;
  agencia: string;
  conta: string;
  condicao_pagamento: string;
  pais: string;
  estado: string;
  codigo_municipio: string;
  bairro: string;
  numero: string;
  endereco: string;
  cep: string;
  descr_compra: string;
  concorrencia: string;
  inicio_contrato: string;
  final_contrato: string;
}

const AddNewSupplier: React.FC = () => {
  const { addNewSupplier } = useSupplier();
  const { getPaymentConditions, getBanks } = useBilling();
  const { userData } = useAuth();

  const navigate = useNavigate();

  const formRef = useRef<FormHandles>(null);
  const cnpjRef = useRef<FormInputRef>(null);
  const testRef = useRef<FormSelectRef>(null);
  const pixRef = useRef<FormInputRef>(null);
  const pixSelectRef = useRef<FormSelectRef>(null);
  const cityRef = useRef<FormAutoCompleteRef>(null);
  const initContractRef = useRef<FormInputRef>(null);
  const endContractRef = useRef<FormInputRef>(null); 

  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [isLoadingBanks, setIsLoadingBanks] = useState<boolean>(false);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);
  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [isLoadingPaymentConditions, setIsLoadingPaymentConditions] = useState<boolean>(false);

  const [controledPaymentInfo, setControledPaymentInfo] = useState({
    agency: "",
    account: "",
  });

  const [description, setDescription] = useState<string>("");

  const [documentMask, setDocumentMask] = useState<string>("999.999.999-999");
  const [phoneMask, setPhoneMask] = useState<string>("9999-99999");
  const [pixMask, setPixMask] = useState<string>("");

  const [citiesOptions, setCitiesOptions] = useState<Array<string>>([]);
  const [paymentOptions, setPaymentOptions] = useState<Array<string>>([]);
  const [countriesOptions, setCountriesOptions] = useState<Array<string>>([]);
  const [selectContractOption, setSelectContractOption] = useState<string | null>(null)

  const [bankOptions, setBankOptions] = useState<string[]>([]);

  const handleSubmit = useCallback(
    async (data: FormData): Promise<void> => {
      setIsLoadingForm(true);
      if (formRef.current) {
        formRef.current.setErrors({});
      }

      let filteredData = {} as FormData;
      let attribute: keyof FormData;

      for (attribute in data) {
        if(attribute === "inicio_contrato" || attribute === "final_contrato"){
          filteredData[attribute] = data[attribute]
        } else {
          filteredData[attribute] = removeSymbolsOnString(data[attribute]);
        }
      }

      const yupValidations: AnyObject = {
        nome: Yup.string().required("Razão Social Obrigatória"),
        fantasia: Yup.string().required("Nome Obrigatório"),
        email: Yup.string().email("E-mail inválido").required("E-mail Obrigatório"),
        cnpj: Yup.string().validateDocument("Documento Inválido").required("CNPJ Obrigatório"),
        inscricao: Yup.string().required("IE Obrigatória"),
        ddd: Yup.string().max(3, "Maxímo 3 numeros").required("Obrigatório"),
        telefone: Yup.string().required("Telefone Obrigatório"),
        contato: Yup.string().required("Nome Contato Obrigatório"),
        integra: Yup.string().required("Obrigatório"),
        descr_compra: Yup.string().required("Campo Obrigatório"),
        concorrencia: Yup.string().required("Concorrência Obrigatória"), 

        banco: Yup.string().required("Banco Obrigatório"),
        agencia: Yup.string().required("Agência Obrigatório"),
        conta: Yup.string().required("Conta Obrigatória"),
        condicao_pagamento: Yup.string().required("Condição Obrigatória"),
        tipo_chave: Yup.string().notRequired(),

        pais: Yup.string().required("País Obrigatório"),
        estado: Yup.string().required("Estado Obrigatório"),
        codigo_municipio: Yup.string().required("Município Obrigatório"),
        bairro: Yup.string().required("Bairro Obrigatório"),
        numero: Yup.string().required("Obrigatório"),
        endereco: Yup.string().required("Endereço Obrigatório"),
        cep: Yup.string().length(9, "CEP Inválido").required("CEP Obrigatório"),
      };

      if(data.concorrencia === "N"){
        yupValidations.inicio_contrato = Yup.string().required("O início do contrato deve ser informado")
        yupValidations.final_contrato = Yup.string().required("O fim do contrato deve ser informado")
      }

      switch (data.tipo_chave) {
        case "01":
        case "04":
          yupValidations.chave = Yup.string().required("Campo obrigatório");
          break;
        case "02":
          yupValidations.chave = Yup.string().email("E-mail inválido").required("Campo obrigatório");
          break;
        case "03":
          yupValidations.chave = Yup.string().validateDocument("Documento Inválido").required("Campo obrigatório");
          break;
        default:
          break;
      }

      try {
        const schema = Yup.object().shape(yupValidations);

        await schema.validate(filteredData, {
          abortEarly: false,
        });

        const {inicio_contrato, final_contrato, ...rest} = filteredData

        const requestData: any = {
          ...rest,
          cnpj: filteredData.cnpj.replace(/\D/g, ""),
          chave: filteredData.chave?.replace(/\D/g, ""),
          cep: filteredData.cep.replace(/\D/g, ""),
          telefone: filteredData.telefone.replace(/\D/g, ""),
          inscricao: !filteredData.inscricao ? "ISENTO" : filteredData.inscricao,
          codigo_municipio: filteredData.codigo_municipio.split(" ")[0].trim(),
          banco: filteredData.banco.split("-")[0].trim(),
          descr_compra: filteredData.descr_compra,
          concorrencia: filteredData.concorrencia === "S",
          pais: filteredData.pais.split(" ")[0].trim(),
          condicao_pagamento: filteredData.condicao_pagamento.split(" ")[0].trim(),
          usuario_inclusao: userData.id,
          data_inclusao: new Date(),
          hora_inclusao: new Date().toLocaleTimeString("pt-BR", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }),
        };

        if(filteredData.concorrencia === "N"){
          requestData.inicio_contrato = inicio_contrato ? new Date(convertDateToEnglishPattern(inicio_contrato)) : ""
          requestData.final_contrato = final_contrato ? new Date(convertDateToEnglishPattern(final_contrato)) : ""
        }

        await addNewSupplier(requestData);

        navigate("/suppliers/");
      } catch (error: any) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (formRef.current) {
            formRef.current.setErrors(errors);
          } else {
            console.log("Não foi possível enviar o formulário");
          }

          toast.warning("Por favor verifique se todos os campos foram preenchidos corretamente");
          return;
        }

        toast.error(getErrorMessage(error));
      } finally {
        setIsLoadingForm(false);
      }
    },
    [addNewSupplier, navigate, userData]
  );

  const addDocumentMask = (value: string) => {
    const filteredValue = value.replace(/\D+/g, "");

    if (filteredValue.length <= 11) {
      setDocumentMask("999.999.999-999");
    } else {
      setDocumentMask("99.999.999/9999-99");
    }
  };

  const addPhoneMask = (value: string) => {
    const filteredValue = value.replace(/\D+/g, "");

    if (filteredValue.length <= 8) {
      setPhoneMask("9999-99999");
    } else {
      setPhoneMask("99999-9999");
    }
  };

  const getPixMask = (value: string) => {
    switch (value) {
      case "01":
        setPixMask("(99)99999-9999");
        break;
      case "03":
        setPixMask("999.999.999-999");
        break;
      default:
        setPixMask("");
    }
  };

  const handleDocumentPixMask = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (pixSelectRef.current?.getValue() === "03") {
      const filteredValue = event.target.value.replace(/\D+/g, "");

      if (filteredValue.length <= 11) {
        setPixMask("999.999.999-999");
      } else {
        setPixMask("99.999.999/9999-99");
      }
    }
  };

  const loadCitiesPerState = useCallback(
    async (event): Promise<void> => {
      setIsLoadingCities(true);
      setCitiesOptions([]);
      cityRef.current?.changeValue(null);

      if (!event.target.value) {
        return setIsLoadingCities(false);
      }

      try {
        const response = await api.get("/consultapadrao", {
          params: {
            tabela: "CC2",
            campos: "CC2_EST,CC2_CODMUN,CC2_MUN",
            pesquisa: event.target.value,
            ordem: "CC2_MUN",
            procura: "CC2_EST",
            branch: userData.selectedBranch?.id || "",
          },
        });

        if (!!response.data.data) {
          setCitiesOptions(response.data.data.consultapadrao.map((city: any) => `${city.CC2_CODMUN} - ${city.CC2_MUN}`));
        }
      } catch (error: any) {
        console.log(error);
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoadingCities(false);
      }
    },
    [userData, cityRef]
  );

  const loadCountries = useCallback(async (): Promise<void> => {
    setIsLoadingCountries(true);

    try {
      const response = await api.get("/consultapadrao", {
        params: {
          tabela: "SYA",
          campos: "YA_CODGI,YA_DESCR",
          ordem: "YA_DESCR",
          branch: userData.selectedBranch?.id || "",
        },
      });

      if (response.data.data) {
        setCountriesOptions(response.data.data.consultapadrao.map((city: any) => `${city.YA_CODGI} - ${city.YA_DESCR}`));
      }
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingCities(false);
    }
  }, [userData]);

  const loadPaymentsConditions = useCallback(async (): Promise<void> => {
    setIsLoadingPaymentConditions(true);

    try {
      const paymentConditions = await getPaymentConditions();
      setPaymentOptions(paymentConditions.map((options) => `${options.id} - ${options.descricao}`));
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingPaymentConditions(false);
    }
  }, [getPaymentConditions]);

  const loadBankOptions = async () => {
    setIsLoadingBanks(true);

    try {
      const banks = await getBanks();

      setBankOptions(banks.map((bank) => `${bank.id} - ${bank.name}`));
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingBanks(false);
    }
  };

  const handleChangeContractOption = (option: string) => {
    setSelectContractOption(option)

    if(option === "S"){
      initContractRef.current?.changeValue(null as any)
      endContractRef.current?.changeValue(null as any)
    }
  }

  useEffect(() => {
    Yup.addMethod(Yup.string, "validateDocument", function (errorMessage) {
      return this.test("test-document-type", errorMessage, function (value) {
        const { path, createError } = this;

        const cnpj = value as string;

        const filteredValue = cnpj.replace(/\D/g, "");

        if (filteredValue.length === 14) {
          return validadeCNPJ(filteredValue) || createError({ path, message: "CNPJ inválido" });
        }

        if (filteredValue.length === 11) {
          return validadeCPF(filteredValue) || createError({ path, message: "CPF inválido" });
        }

        return createError({ path, message: errorMessage });
      });
    });
  }, []);

  useEffect(() => {
    loadCountries();
    loadPaymentsConditions();
    loadBankOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormCard title="Dados Gerais">
          <Grid container>
            <Grid item xs={12} md={3}>
              <FormInputMask
                ref={cnpjRef}
                mask={documentMask}
                name="cnpj"
                label="CNPJ/CPF *"
                type="text"
                size="small"
                fullWidth
                onChange={(event) => addDocumentMask(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <FormInput
                variant="outlined"
                name="nome"
                label="Razão Social *"
                type="text"
                size="small"
                fullWidth
                inputProps={{ maxLength: 60 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                variant="outlined"
                name="fantasia"
                label="Nome Fantasia *"
                type="text"
                size="small"
                fullWidth
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput variant="outlined" name="email" label="Email *" size="small" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                variant="outlined"
                name="inscricao"
                label="Incrição Estadual *"
                type="text"
                size="small"
                fullWidth
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={4} md={1}>
              <FormInputMask name="ddd" label="DDD *" type="text" size="small" mask="99" fullWidth />
            </Grid>
            <Grid item xs={8} md={5}>
              <FormInputMask
                mask={phoneMask}
                name="telefone"
                label="Telefone *"
                type="text"
                size="small"
                fullWidth
                onChange={(event) => addPhoneMask(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput variant="outlined" name="contato" label="Contato *" type="text" size="small" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormSelect
                ref={testRef}
                name="integra"
                label="Integra LUFT? *"
                size="small"
                fullWidth
                options={SupplierFormDefaultValue.integra}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <FormAutoComplete
                name="condicao_pagamento"
                label="Condição de Pagamento *"
                options={paymentOptions}
                loading={isLoadingPaymentConditions}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <p className="w-full text-end" style={{ marginTop: "-22px" }}>{description.length} / 250</p>
              <FormInput
                variant="outlined"
                name="descr_compra"
                label="Descrição do serviço ou produto *"
                type="text"
                size="small"
                onChange={(event: any) => setDescription(event.target.value)}
                fullWidth
                inputProps={{ maxLength: 250 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormSelect
                name="concorrencia"
                label="Concorrência *"
                type="text"
                size="small"
                fullWidth
                options={[
                  { value: "S", label: "Sim" },
                  { value: "N", label: "Não" },
                ]}
                onChange={(event) => {
                  handleChangeContractOption(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormDatePicker 
                ref={ initContractRef }
                name="inicio_contrato"
                views={['day']}
                inputFormat="dd/MM/yyyy"
                label="Início contrato"
                size="small"
                fullWidth
                disabled={selectContractOption !== "N"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormDatePicker 
                ref={ endContractRef }
                name="final_contrato"
                views={['day']}
                inputFormat="dd/MM/yyyy"
                label="Final contrato"
                size="small"
                fullWidth
                disabled={selectContractOption !== "N"}
              />
            </Grid>
          </Grid>
        </FormCard>
        <FormCard title="Dados Bancários">
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormAutoComplete name="banco" label="Banco *" options={bankOptions} loading={isLoadingBanks} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                variant="outlined"
                name="agencia"
                label="Agência *"
                size="small"
                fullWidth
                inputProps={{ maxLength: 5 }}
                value={controledPaymentInfo.agency}
                onChange={(event: any) => {
                  const value = event.target.value.replace(/\D+/g, "");
                  setControledPaymentInfo((prev) => ({ ...prev, agency: value }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                variant="outlined"
                name="conta"
                label="Conta *"
                size="small"
                fullWidth
                value={controledPaymentInfo.account}
                onChange={(event: any) => {
                  const value = event.target.value.replace(/\D+/g, "");
                  setControledPaymentInfo((prev) => ({ ...prev, account: value }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormSelect
                ref={pixSelectRef}
                name="tipo_chave"
                label="Tipo Chave"
                fullWidth
                size="small"
                enableClearValue
                onClean={() => {
                  pixRef.current?.changeValue("");
                  setPixMask("");
                }}
                onChange={(event: any) => {
                  pixRef.current?.changeValue("");
                  getPixMask(event.target.value);
                }}
                options={[
                  { label: "Telefone", value: "01" },
                  { label: "E-mail", value: "02" },
                  { label: "CPF/CNPJ", value: "03" },
                  { label: "Chave Aleatória", value: "04" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <FormInputMask
                ref={pixRef}
                mask={pixMask}
                name="chave"
                label="Chave Pix"
                size="small"
                type="text"
                fullWidth
                onChange={handleDocumentPixMask}
              />
            </Grid>
          </Grid>
        </FormCard>
        <FormCard title="Endereço">
          <Grid container>
            <Grid item xs={6} md={3}>
              <FormAutoComplete
                label="País *"
                name="pais"
                options={countriesOptions}
                loading={isLoadingCountries}
                defaultValue={countriesOptions.find((opt) => opt.includes("BRASIL"))}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormSelect
                name="estado"
                label="Estado *"
                size="small"
                fullWidth
                onChange={loadCitiesPerState}
                options={SupplierFormDefaultValue.states}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormAutoComplete
                ref={cityRef}
                label="Município *"
                name="codigo_municipio"
                options={citiesOptions}
                loading={isLoadingCities}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInputMask label="CEP *" name="cep" size="small" fullWidth mask="99999-999" />
            </Grid>
            <Grid item xs={9} md={8}>
              <FormInput variant="outlined" label="Endereço *" name="endereco" size="small" fullWidth />
            </Grid>
            <Grid item xs={3} md={1}>
              <FormInput
                variant="outlined"
                label="Nº *"
                name="numero"
                size="small"
                type="number"
                fullWidth
                inputProps={{ maxLength: 5 }}
                loading={isLoadingCities}
              />
            </Grid>
            <Grid item xs={9} md={6}>
              <FormInput variant="outlined" label="Bairro *" name="bairro" size="small" fullWidth loading={isLoadingCities} />
            </Grid>
          </Grid>
        </FormCard>
        <FormActions>
          <Button type="button" size="large" variant="text" disabled={isLoadingForm} onClick={() => navigate("/suppliers/")}>
            Fechar
          </Button>
          <Button type="submit" variant="contained" color="primary" size="large" disabled={isLoadingForm}>
            {isLoadingForm ? "Aguarde" : "Gravar"}
          </Button>
        </FormActions>
      </Form>
    </Container>
  );
};

export default AddNewSupplier;
