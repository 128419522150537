import React, { useImperativeHandle } from 'react'
import { useRef, useEffect, useState, forwardRef } from 'react'
import { useField } from '@unform/core'

import ptBrLocale from 'date-fns/locale/pt-BR'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { DatePicker } from '@mui/lab'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
/* import { DatePickerView } from '@mui/lab/DatePicker/DatePicker' */

import { 
    Container, 
    Input, 
    ErrorMessage, 
    CheckIcon,
} from './style'

export interface FormDatePickerRef{
    changeValue(value: string | null): void
    getValue(): string | unknown
}

interface FormDatePickerProps{
    name: string,
    label: string,
    inputFormat: string | undefined
    initialValue?: string | Date,
    fullWidth?: boolean
    size?: "small" | "medium" | undefined
    variant?: "filled" | "outlined" | "standard"
    views?: readonly any[] | undefined //verificar
    disabled?: boolean
    minDate?: Date
    onChange?: (date: unknown, keyboardInputValue?: string | undefined) => void
}

const FormDatePicker: React.ForwardRefRenderFunction<FormDatePickerRef,FormDatePickerProps> = ({
    name, 
    label, 
    size, 
    fullWidth,
    variant, 
    onChange,
    initialValue,
    ...rest
}, ref) => {

    const inputRef = useRef<HTMLInputElement>(null)
    const { fieldName, error, registerField, defaultValue } = useField(name)

    const [hasAnError, setHasAnError] = useState<boolean>(false)
    const [value, setValue] = useState<string | unknown>(defaultValue || initialValue || null)

    const [pickerKey, setPickerKey] = useState("pickerKey");

    useImperativeHandle(ref, () => ({
        changeValue: (value) => {
            setValue(value)
            if(inputRef.current){
                inputRef.current.value = value || ""

                if(!value){
                    setPickerKey(Math.random().toString())
                }
            }
        },
        getValue: () => ( inputRef.current?.value as string)
    }))

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value",
            clearValue(ref, newValue) {
                setValue(null)
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[registerField, fieldName, inputRef.current])

    useEffect(() => {
        if(!!error){ 
            setHasAnError(true) 
        }
    }, [error])

    useEffect(() => {
        setValue(defaultValue || initialValue || null) 
    },[defaultValue, initialValue])

    useEffect(() => {
        if(value === ""){
            setHasAnError(false)
        }
    }, [value])

    return(
        <Container fullWidth={!!fullWidth}>
            <LocalizationProvider dateAdapter={ AdapterDateFns } locale={ ptBrLocale }>
                <DatePicker
                    { ...rest }
                    key={pickerKey}
                    value={ value }
                    label={ label }
                    inputRef={ inputRef }
                    onChange={(date, keyboardInputValue) => {
                        setValue(date)
                        if(onChange){
                            onChange(date, keyboardInputValue)
                        }
                    }}
                    renderInput={(params) => (
                        <Input
                            {...params}
                            error={ false }
                            $isError={!!error}
                            $hasAnError={ hasAnError }
                            name={ name }
                            variant={ variant }
                            size={size}
                            fullWidth={fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
            { !!error ?
                <ErrorMessage> { error } </ErrorMessage>
            : (!error && hasAnError) &&
                <CheckIcon />
            }
        </Container>
    )
}

export default forwardRef(FormDatePicker)