import { useCallback, useRef, useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Form } from "@unform/web"
import { FormHandles } from "@unform/core"
import Reference from "yup/lib/Reference"
import { toast } from "react-toastify"
import * as Yup from 'yup'
import { compareAsc } from 'date-fns'

import { NewBuyOrderRequest } from "../../../@types/buyOrder"
import { CostCenter } from "../../../@types/billing"

import { useAuth } from '../../../hooks/auth'
import { useBuyOrder } from "../../../hooks/buyOrders"
import { useBilling } from "../../../hooks/billing"

import { validadeCNPJ, validadeCPF } from "../../../utils/validations/validateDocuments"
import { convertDateToEnglishPattern } from '../../../utils/format/convertDateToEnglishPattern'
import getValidationErros from "../../../utils/validations/getValidationErros"
import { formatValue, transformValueToNumberType } from "../../../utils/format/formatValue"
import { cnpjMask, cpfMask } from "../../../utils/format/masks"
import { OCFormDefaultValue } from "../../../data/formValues"
import { getErrorMessage } from "../../../utils/validations/getErrorMessage"

import FormInput, { FormInputRef } from "../../../components/Form/FormInput"
import FormSelect from "../../../components/Form/FormSelect"
import FormDatePicker from "../../../components/Form/FormDataPicker"
import { Product, SelectProductModal } from "../../../components/Modal/SelectProduct"
import { Supplier, SelectSupplierModal } from '../components/SelectSupplier'
import FormAutoComplete, { FormAutoCompleteRef } from "../../../components/Form/FormAutoComplete"
import { Button } from "../../../components/Buttons/Button"
import { FormCard } from "../../../components/Form/FormCard"
import { AddProductButton } from "../components/AddProductButton"
import { DataTable } from "../../../components/Tables/Datagrid"
import { GridColumn } from "../../../@types/datagrid"
/* import { DataGrid, GridColumns, GridValueSetterParams, ptBR } from '@mui/x-data-grid' */
import { GridValueSetterParams } from "@mui/x-data-grid"


import {
    Grid,
    CircularProgress,
    Autocomplete,
    TextField,
    Switch,
    Tooltip
} from "@mui/material"

import {
    AttachFile,
    Close as CloseIcon,
    Search as SearchIcon
} from "@mui/icons-material"

import {
    CNPJActions,
    Container, 
    FormActions, 
    ProductsSelection, 
    RemoveButton, 
    SearchSupplierButton
} from "./style"
import { useAppParams } from "../../../hooks/appParams"
import OptionsButtons from "../../../components/Buttons/OptionsButtons"
import AttachDialog, { UploadedFile } from "../../../components/Modal/AttachDialog"
import { AttachRequestData } from "../../../@types/attachFiles"
import { useAttachFiles } from "../../../hooks/attachFiles"
import { convertFileToBase64 } from "../../../utils/format/convertFiles"
import { formatText } from "../../../utils/format/formatText"
import { Info } from "phosphor-react"

interface FormData{
    requester: string
    condicao_pagamento: string
    type: string
    brand: string
    dateDelivery: string
    dateOC: string
    sede: string
    delivery: string
    cnpj: string
    razao: string
    nome: string
    ie: string
    email: string
    observation: string
    cod: string
    loja: string
    obs_tipo_pagto: string
    valor_orcamento1: string
    valor_orcamento2: string
    valor_orcamento3: string
}

interface AttachBudget{
    budget2: Omit<AttachRequestData, "id">
    budget3: Omit<AttachRequestData, "id">
}

interface Budget{
    valor_orcamento1: string | number
    valor_orcamento2: string | number
    valor_orcamento3: string | number
}

interface SetValueGridProps extends GridValueSetterParams{
    row: Product
}

const patternItem = "000"

export default function FormRequests(){

    const navigate = useNavigate()

    const { userData } = useAuth()
    const { params } = useAppParams()
    const { getCostCenter, getPaymentConditions } = useBilling()
    const { addNewOrder } = useBuyOrder()
    const { onUpload } = useAttachFiles()

    const formRef = useRef<FormHandles>(null)

    const cnpjRef             = useRef<FormInputRef>(null)
    const reasonRef           = useRef<FormInputRef>(null)
    const nameRef             = useRef<FormInputRef>(null)
    const subRef              = useRef<FormInputRef>(null)
    const emailRef            = useRef<FormInputRef>(null)
    const codRef              = useRef<FormInputRef>(null)
    const lojaRef             = useRef<FormInputRef>(null)
    const sedeRef             = useRef<FormInputRef>(null)
    const paymentConditionRef = useRef<FormAutoCompleteRef>(null)

    const [isLoadingCC, setIsLoadingCC]                               = useState<boolean>(false)
    const [isLoadingSubmit, setIsLoadingSubmit]                       = useState<boolean>(false)
    const [isLoadingPaymentConditions, setIsLoadingPaymentConditions] = useState<boolean>(false)

    const [openProductsDialog, setOpenProductsDialog]   = useState<boolean>(false)
    const [openSuppliersDialog, setOpenSuppliersDialog] = useState<boolean>(false)
    const [openUploadFileModal, setOpenUploadFileModal] = useState<boolean>(false)

    const [selectedOcType, setSelectedOCType] = useState<string>("")

    const [ccOptions, setCcOptions]           = useState<Array<CostCenter>>([])
    const [productsList, setProductsList]     = useState<Array<Product>>([])
    const [totalProducts, setTotalPRoducts]   = useState<string>(formatValue(0))
    const [paymentOptions, setPaymentOptions] = useState<Array<string>>([])

    const [hasConcurrenceProvider, setHasConcurrenceProvider] = useState<boolean>(false)

    const [initialSearchSupplier, setInitialSearchSupplier] = useState<string>("")

    const [budgets, setBudgets] = useState<Budget>({} as Budget)
    const [budgetsAttachments, setBudgetAttachments] = useState<AttachBudget>({} as AttachBudget)
    const [selectedAttachBudget, setSelectedAttachBudget] = useState<"budget2" | "budget3" | null>(null)

    const consistInBudgetsRules = useMemo(() => {
        let isActive = false

        const activeNewProviderRule = params.find(param => (
            param.parametro === "ZZ_CONEWFO"
        ))

        const maxQuantityWithoutRule = Number(params.find(param => (
            param.parametro === "ZZ_VLIMORC"
        ))?.conteudo || 20000)

        if(!activeNewProviderRule){
            isActive = true
        }

        isActive = activeNewProviderRule?.conteudo === ".T." ? true : false

        const showBudegtsRules = isActive
            ? transformValueToNumberType(totalProducts) > maxQuantityWithoutRule && hasConcurrenceProvider
            : transformValueToNumberType(totalProducts) > maxQuantityWithoutRule

        return showBudegtsRules
    },[hasConcurrenceProvider, params, totalProducts])

    const getBudgetValue = useCallback((content: string | number): number => {
        const currentValue = isNaN(Number(content))
            ? transformValueToNumberType(content as string)
            : Number(content)

        return currentValue
    },[])

    const onQuantityChanges = useCallback(({ value, row }: SetValueGridProps): Product => {

        setProductsList(prev => prev.map(tableRow => {
            if(tableRow.id === row.id){
                return({ 
                    ...tableRow,
                    quantidade: !!value ? Math.trunc(value as number) : 0,
                    valor_total: formatValue(
                        isNaN(row.valor_unitario as number) 
                            ? transformValueToNumberType(row.valor_unitario as string) * (!!value ? Math.trunc(value as number) : 0)
                            : (row.valor_unitario as number) * (!!value ? Math.trunc(value as number) : 0) 
                    )
                })      
            } else{
                return tableRow
            }
        }))

        return row as Product
    }, [])

    const onValueChanges = useCallback(({ value, row }: SetValueGridProps): Product => {
        const filteredValue = value?.toString().replaceAll(".", "").replaceAll(",",".") || ""
        const regexMatches = filteredValue.match("^[0-9.]*")

        if(regexMatches === null){ return row }

        if(!!regexMatches[0]){
            setProductsList(prev => prev.map(tableRow => {
                if(tableRow.id === row.id){
                    const unitValue = formatValue(Number(regexMatches[0]) <= 0 ? 0 : Number(Number(regexMatches[0])), 4)
                    const totalValue = transformValueToNumberType(unitValue) * (tableRow.quantidade as number)
                    
                    return({
                        ...tableRow,
                        valor_unitario: unitValue,
                        valor_total: formatValue(totalValue)
                    })
                } else{
                    return tableRow
                }
            }))
        }

        return row
    }, [])
    
    const onSelectProduct = useCallback((products: Array<Product>): void => {        
        const newProducts = products.map((product, index) => ({
            ...product,
            id: productsList.length === 0 ? index + 1 : productsList.length + (index + 1),
            quantidade: 0,
            valor_unitario: formatValue(0),
            valor_total: formatValue(0 * 1)
        }))
        
        setProductsList(prev => [...prev, ...newProducts])
    }, [productsList])

    const onSelectSupplier = useCallback((row: Supplier): void => {
        console.log({ row })

        setHasConcurrenceProvider(!!row.concurrence)

        cnpjRef.current?.changeValue(row.cnpj.length === 14 ? cnpjMask(row.cnpj) : cpfMask(row.cnpj))
        reasonRef.current?.changeValue(row.reason)
        nameRef.current?.changeValue(row.name)
        subRef.current?.changeValue(row.ie || "ISENTO")
        emailRef.current?.changeValue(row.email)
        codRef.current?.changeValue(row.cod)
        lojaRef.current?.changeValue(row.loja)
    }, [cnpjRef])

    const onSelectCC = useCallback((id: string, value: string): void => {
        if(!!value){
            const selectedCC = ccOptions.find(cc => cc.id === value.split("-")[0].trim())

            if(!selectedCC?.nome_aprovador){
                toast.warn("Centro de custo selecionado não possui aprovador, por favor contate o financeiro.")
            }

            const hasSubstitute = !!selectedCC?.substitutos[0]

            setProductsList(prev => prev.map(tableRow => {
                if(tableRow.id === id){
                    return({
                        ...tableRow,
                        cc: value,
                        cc_pel: selectedCC?.pel,
                        cc_approver: `${selectedCC?.nome_aprovador}${ hasSubstitute ? ` (${selectedCC?.substitutos[0]?.nome})`: ""}`
                    })
                } else{
                    return tableRow
                }
            }))
        }
    }, [ccOptions])

    const verifyProductsSelected = useCallback((): boolean => {
        if(productsList.length <= 0){
            toast.info("Por favor adicione um novo produto")
            return false
        }

        let containValue = true
        let containCC = true
        let containQuantity = true

        productsList.forEach(item => {
            if(transformValueToNumberType(item.valor_unitario as string) <= 0){
                containValue = false
            }
            if(item.cc === "" || item.cc === undefined || item.cc === null){
                containCC = false
            }
            if((item.quantidade as number) <= 0){
                containQuantity = false
            }
        })

        if(!containValue){
            toast.warning("Por favor verifique o valor unitário de cada produto selecionado")
        }

        if(!containCC){
            toast.warning("Por favor verifique o centro de custo de cada produto selecionado")
        }

        if(!containQuantity){
            toast.warning("Por favor verifique a quantidade de cada produto selecionado")
        }

        if(containValue && containCC && containQuantity){
            return true
        } else{
            return false
        }
    },[productsList])

    const loadingPaymentsConditions = useCallback(async(ocType: string): Promise<void> => {
        if(!ocType){ return }
        
        setIsLoadingPaymentConditions(true)
        setPaymentOptions([])

        try{
            const paymentConditions = await getPaymentConditions(ocType)
            setPaymentOptions(paymentConditions.map((options) => `${options.id} - ${options.descricao}`))

        } catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoadingPaymentConditions(false)
        }
    },[getPaymentConditions])

    const handleMarkCorpCardProduct = useCallback((productId) => {
        setProductsList(prev => prev.map(item => item.id === productId ? ({
            ...item,
            cartao_corporativo: !item.cartao_corporativo
        }): item))
    },[])

    const handleSubmit = async (data: FormData): Promise<void>=> {
        if(isLoadingSubmit){ return }

        try{
            if(formRef.current){
                formRef.current.setErrors({})
            }

            let filteredData = {} as FormData
            let attribute: keyof FormData

            for(attribute in data){
                if(attribute !== "dateOC" && attribute !== "dateDelivery"){
                    /* if(attribute !== "delivery"){
                        filteredData[attribute] = removeSymbolsOnString(data[attribute])
                    } else{ */
                        filteredData[attribute] = data[attribute]
                    /* } */
                } else{
                    filteredData[attribute] = data[attribute]
                }
            }

            const validationShape: any = {
                type: Yup.string().required("Tipo Obrigatório"),
                brand: Yup.string().required("Marca Obrigatório"),
                dateOC: Yup.string().notRequired(),
                dateDelivery: Yup.string().compareDate(Yup.ref("dateOC"), "Data não pode ser menor que emissão").required("Data Obrigatória"),
                sede: Yup.string().required("Sede Obrigatória"),
                delivery: Yup.string().required("Local de Entrega Obrigatório"),
                condicao_pagamento: Yup.string().required("Condicao de Pagamento Obrigatório"),
                ie: Yup.string().notRequired(),
                email: Yup.string().notRequired(),
                observation: Yup.string().required("Descrição Obrigatória"),
                cnpj: Yup.string().required("Fornecedor Obrigatório"),
                cod: Yup.string().required("Código Obrigatório"),
                loja: Yup.string().required("Loja Obrigatória"),
                razao: Yup.string().required("Razão Obrigatória"),
                nome: Yup.string().required("Nome Obrigatório"),
            }

            if(consistInBudgetsRules){
                validationShape.valor_orcamento2 = Yup.string().required("Por favor preencha a opção de orçamento")
                validationShape.valor_orcamento3 = Yup.string().required("Por favor preencha a opção de orçamento")
            }

            if(data.type !== "1"){
                validationShape.obs_tipo_pagto = Yup.string().max(240, "Número limite de caracteres é 240").required("Por favor informe o motivo")
            }

            const schema = Yup.object().shape(validationShape)

            await schema.validate(filteredData, {
                abortEarly: false
            })

            if(!verifyProductsSelected()){ return }

            if(consistInBudgetsRules){
                if(
                    transformValueToNumberType(data.valor_orcamento2) < 20000 ||
                    transformValueToNumberType(data.valor_orcamento3) < 20000 
                ){
                    toast.warn("Os orçamentos devem ter o valor mínimo de R$20.000,00")
                    return
                }

                let hasAttachFileError = false

                if(!budgetsAttachments.budget2){
                    toast.warn("Por favor realize o anexo do segundo orçamento")
                    hasAttachFileError = true
                }

                if(!budgetsAttachments.budget3){
                    toast.warn("Por favor realize o anexo do terceiro orçamento")
                    hasAttachFileError = true
                }

                if(hasAttachFileError){
                    return
                }
            }

            const hasInvalidCostCenterSelected = productsList.some(product => !product.cc_approver)

            if(hasInvalidCostCenterSelected){
                toast.warn("Alguns dos itens selecionados não possui centro de custo com aprovador vinculado, por favor contate o financeiro.")
                return
            }

            const postData: Omit<NewBuyOrderRequest, "id"> = {
                tipo: filteredData.type,
                marca: filteredData.brand,
                faturar_em: filteredData.sede,
                data_emissao: new Date(convertDateToEnglishPattern(filteredData.dateOC)),
                data_entrega: new Date(convertDateToEnglishPattern(filteredData.dateDelivery)),
                local_entrega: filteredData.delivery,
                codigo_fornecedor: filteredData.cod,
                loja_fornecedor: filteredData.loja,
                codigo_solicitante: userData.id || "",
                condicao_pagamento: filteredData.condicao_pagamento.split("-")[0].trim(),
                total: transformValueToNumberType(totalProducts),
                //observacao: filteredData.observation?.replaceAll("\n", " ").replaceAll('"', "").replaceAll("'", "").replaceAll("/", "-").replaceAll("\\", ""),
                observacao: filteredData.observation ? formatText(filteredData.observation) : "",
                obs_tipo_pagto: filteredData.obs_tipo_pagto ? formatText(filteredData.obs_tipo_pagto) : "",
                valor_orcamento1: transformValueToNumberType(totalProducts),
                valor_orcamento2: consistInBudgetsRules ? transformValueToNumberType(data.valor_orcamento2) : null,
                valor_orcamento3: consistInBudgetsRules ? transformValueToNumberType(data.valor_orcamento3) : null,
                itens: productsList.map((product, index) => ({
                    item: patternItem.substring(0, patternItem.length - (index + 1).toString().length) + (index + 1).toString(),
                    produto: product.codigo,
                    centro_custo: product.cc.split(" ")[0].trim(),
                    quantidade: product.quantidade as number,
                    data_venc_titulo: product.data_venc_titulo ? product.data_venc_titulo : "",
                    valor_total: transformValueToNumberType(product.valor_total as string),
                    valor_unitario: transformValueToNumberType(product.valor_unitario as string),
                    cartao_corporativo: !!product.cartao_corporativo ? "S" : "N"
                }))
            }

            setIsLoadingSubmit(true)

            const newOrderId = await addNewOrder(postData)

            if(consistInBudgetsRules){

                const [budget2File, budget3File] = await Promise.all([
                    convertFileToBase64(budgetsAttachments.budget2.file as File),
                    convertFileToBase64(budgetsAttachments.budget3.file as File)
                ])

                await Promise.all([
                    onUpload({ 
                        files: [{
                            fileContent: budget2File as string,
                            fileName: budgetsAttachments.budget2.fileName
                        }], 
                        type: 1, 
                        id: newOrderId 
                    }),
                    onUpload({ 
                        files: [{
                            fileContent: budget3File as string,
                            fileName: budgetsAttachments.budget3.fileName
                        }], 
                        type: 1, 
                        id: newOrderId 
                    })
                ])
            }

            setIsLoadingSubmit(false)

            toast.success("A solicitação foi realizada com sucesso")

            navigate("/buy-orders")
            
        } catch(error: any){
            console.log(error)
            if(error instanceof Yup.ValidationError){
                const errors = getValidationErros(error)
                if(formRef.current){
                    formRef.current.setErrors(errors)
                } else{
                    console.log("Não foi possível enviar o formulário")
                }
                
                verifyProductsSelected()
                
                toast.warning("Por favor verifique se todos os campos foram preenchidos corretamente")
                return
            }
            
            console.log(error)
            setIsLoadingSubmit(false)
            toast.error(getErrorMessage(error))
            return
        }
    }

    const getCCOptions = useCallback(async(): Promise<void> => {
        setIsLoadingCC(true)
        try{
            const costCenters = await getCostCenter()

            setCcOptions(costCenters)
        } catch(error: any){
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoadingCC(false)
        }
    }, [getCostCenter])

    const removeProduct = useCallback((id: string) => {
        setProductsList(prev => prev
            .filter(product => product.id !== id)
            .map((filteredProduct, index) => ({ ...filteredProduct, id: index + 1 }))
        )
    },[])

    const handleBudgetChanges = (value: string | number, key: keyof Budget) => {
        setBudgets(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const handleBudgetOnBlur = (value: string | number, key: keyof Budget) => {
        const currentValue = getBudgetValue(value)

        setBudgets(prev => ({
            ...prev,
            [key]: formatValue(currentValue)
        }))
    }

    const getDeliveryLocationsValue = (): string => {
        const item = OCFormDefaultValue.localeDelivery.find(item => item.branch === userData.selectedBranch?.id)

        if(!item){
            return ""
        }

        if(item.itens.length > 1){
            return ""
        }

        return item.itens[0].value
    }

    const handleAttachBudget = useCallback(async(files: UploadedFile[]) => {
        if(selectedAttachBudget){
            setBudgetAttachments(prev => ({
                ...prev,
                [selectedAttachBudget]: {
                    ...files[0],
                    fileName: files[0].filename
                }
            }))
        }

        setSelectedAttachBudget(null)
        setOpenUploadFileModal(false)
    },[selectedAttachBudget])

    const savings = useMemo(() => {
        const total = transformValueToNumberType(totalProducts)
        
        const budgetsValues = {
            budget1: getBudgetValue(budgets.valor_orcamento1 || 0),
            budget2: getBudgetValue(budgets.valor_orcamento2 || 0),
            budget3: getBudgetValue(budgets.valor_orcamento3 || 0)
        }

        const savingsValues = {
            saving1: formatValue(budgetsValues.budget1 - total),
            saving2: formatValue(budgetsValues.budget2 - total),
            saving3: formatValue(budgetsValues.budget3 - total)
        }

        return savingsValues
    },[totalProducts, budgets, getBudgetValue])

    const columns: GridColumn[] = [
        { 
            field: 'codigo', 
            headerName: 'Código Produto', 
            minWidth: 140, 
            align: "center" 
        },
        { 
            field: 'descricao', 
            headerName: 'Descrição', 
            minWidth: 400
        },
        { 
            field: 'cc', 
            headerName: 'Centro de Custo',
            minWidth: 400, 
            renderCell: (row) => (
                <Autocomplete
                    fullWidth
                    value={ row.cc || null }
                    options={ ccOptions.map((item) => `${item.id} - ${item.descricao}`) }
                    loading={ isLoadingCC }
                    getOptionLabel={option => option}
                    loadingText="Carregando..."
                    noOptionsText="Sem resultados"
                    onChange={(_, option) => onSelectCC(row.id, option || "")} 
                    renderInput={props => (
                        <TextField
                            {...props} 
                            hiddenLabel
                            variant="standard"
                            size="small"
                            placeholder="Selecione"
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                        />
                    )}
                />
            )
        },
        { 
            field: 'cc_pel', 
            headerName: 'P&L',
            minWidth: 400
        },
        { 
            field: 'cc_approver', 
            headerName: 'Responsável',
            minWidth: 200
        },
        { 
            field: 'data_venc_titulo', 
            headerName: 'Data Venc. Título (apenas para prod. adiantamento)',
            minWidth: 400,
            renderCell: (row) => (
                <div style={{ marginTop: "-12px"}}>
                    <FormDatePicker 
                        name="data_venc_titulo"
                        views={['day']}
                        inputFormat="dd/MM/yyyy"
                        label=" "
                        variant="standard"
                        size="small"
                        fullWidth
                        disabled={ row.codigo !== "ADTO"}
                        onChange={date => {
                            setProductsList(prev => prev.map(tableRow => {
                                if(tableRow.id === row.id){
                                    return({
                                        ...tableRow,
                                        data_venc_titulo: date as string
                                    })
                                } else{
                                    return tableRow
                                }
                            }))
                        }}
                    />
                </div>
            )
        },
        {
            field: 'ncm', 
            headerName: 'NCM', 
            align: "center", 
            headerAlign: "center",
            minWidth: 150 
        },
        { 
            field: 'cartao_corporativo', 
            headerName: 'Cartão Corporativo?', 
            align: "center", 
            headerAlign: "center",
            minWidth: 150,
            renderCell: (row) => (
                <Switch onChange={() => handleMarkCorpCardProduct(row.id)} />
            ),
        },
        { 
            field: 'um', 
            headerName: "Unidade de Medida",
            align: "center", 
            headerAlign: "center", 
            minWidth: 170
        },
        { 
            field: 'quantidade', 
            headerName: 'Quantidade', 
            align: "center", 
            headerAlign: "center", 
            minWidth: 120,
            renderCell: (row) => (
                <TextField 
                    size="small"
                    fullWidth
                    variant="standard"
                    value={ row.quantidade }
                    onChange={event => {
                        onQuantityChanges({
                            value: event.target.value.replace(/\D+/g, ''),
                            row
                        })
                    }}
                />
            )
        },
        { 
            field: 'valor_unitario', 
            headerName: "Valor Unitário",
            headerAlign: "center", 
            align: "center", 
            minWidth: 170, 
            renderCell: (row) => (
                <TextField 
                    size="small"
                    fullWidth
                    variant="standard"
                    value={ row.valor_unitario }
                    onChange={event => {
                        setProductsList(prev => prev.map(tableRow => {
                            if(tableRow.id === row.id){
                                return({
                                    ...tableRow,
                                    valor_unitario: event.target.value
                                })
                            } else{
                                return tableRow
                            }
                        }))
                    }}
                    onBlur={event => {
                        onValueChanges({
                            value: event.target.value,
                            row
                        })
                    }}
                />
            )
        },
        { 
            field: 'valor_total', 
            headerName: "Total",
            align: "center", 
            headerAlign: "center", 
            minWidth: 170
        },
        { 
            field: 'remove', 
            headerName: "Opções", 
            align: "center", 
            headerAlign: "center", 
            renderCell: (row) => (
                <RemoveButton type="button" onClick={() => removeProduct(row.id) }>
                    <CloseIcon/>
                </RemoveButton>
            )
        }
    ]

    useEffect(() => {
        Yup.addMethod(Yup.string, "validateDocument", function(errorMessage){
            return this.test("test-document-type", errorMessage, function(value){
                const { path, createError } = this

                const cnpj = value || ""

                const filteredValue = cnpj.replace(/\D/g, "")

                if(filteredValue.length === 14){
                    return ( validadeCNPJ(filteredValue) || createError({ path, message: "CNPJ inválido" }) )
                }

                if(filteredValue.length === 11){
                    return ( validadeCPF(filteredValue) || createError({ path, message: "CPF inválido" }) )
                }

                return ( createError({ path, message: errorMessage }) )
            })
        })

        Yup.addMethod(Yup.string, "compareDate", function(dateToCompare: Reference<string> | string, errorMessage){
            return this.test("test-document-type", errorMessage, function(currentDate){
                const { path, createError, parent } = this

                let date: string

                if(typeof(dateToCompare) === "object"){
                    date = parent[dateToCompare.key]
                } else{
                    date = dateToCompare as string
                }

                if(!currentDate){
                    return ( createError({ path, message: errorMessage }) ) 
                }

                const compareResult = compareAsc(
                    new Date(convertDateToEnglishPattern(date)),
                    new Date(convertDateToEnglishPattern(currentDate))
                )

                if(compareResult <= 0){
                    return true
                }
                
                return ( createError({ path, message: errorMessage }) )
            })
        })
    }, [])

    useEffect(() => {
        const total = formatValue(productsList.reduce((accumulator, currentValue) => accumulator + transformValueToNumberType(currentValue.valor_total as string), 0))
        setTotalPRoducts(total)
    }, [productsList])

    useEffect(() => {
        sedeRef.current?.changeValue(`${userData.selectedBranch?.id} - ${userData.selectedBranch?.name}`)
    }, [userData.selectedBranch])

    useEffect(() => { 
        getCCOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
        <Container>
            <Form 
                ref={ formRef } 
                onSubmit={ handleSubmit } 
                onKeyPress={(event => {
                    if(event.key === "Enter"){
                        event.preventDefault()
                    }
                })}
                initialData={{ 
                    requester: userData.username,
                    sede: `${userData.selectedBranch?.id} - ${userData.selectedBranch?.name}`,
                    delivery: getDeliveryLocationsValue(),
                    dateOC: convertDateToEnglishPattern(new Date().toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                    })),
                    dateDelivery: convertDateToEnglishPattern(new Date().toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                    }))
                } as FormData}
            >
                <FormCard title="Dados Gerais">
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <FormSelect
                                name="type"
                                label="Tipo Solicitação *"
                                type="text"
                                size="small"
                                fullWidth
                                options={ OCFormDefaultValue.typeRequest }
                                onChange={(event) => {
                                    paymentConditionRef.current?.changeValue(null)
                                    setSelectedOCType(event.target.value)
                                    loadingPaymentsConditions(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormSelect
                                name="brand"
                                label="Marca *"
                                type="text"
                                size="small"
                                fullWidth
                                options={ OCFormDefaultValue.brands }
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormDatePicker 
                                name="dateOC"
                                views={['day']}
                                inputFormat="dd/MM/yyyy"
                                label="Data da Ordem"
                                size="small"
                                fullWidth
                                /* value={ new Date() } */
                                disabled
                            />
                        </Grid>
                        <Grid 
                            item 
                            xs={12} 
                            md={4} 
                            display="flex" 
                            direction="row" 
                            alignItems="center" 
                            gap={2}
                        >
                            <FormDatePicker 
                                name="dateDelivery"
                                views={['day']}
                                inputFormat="dd/MM/yyyy"
                                label="Data prevista compra/realização do serviço *"
                                size="small"
                                fullWidth
                                minDate={ new Date() }
                            />
                            <Tooltip title={`Informe aqui a data prevista para realização da compra ou realização do serviço.\nVENCIMENTO deve ser informado em condições de pagamento!`}>
                                <Info size={22}/>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormInput
                                variant="outlined"
                                name="requester"
                                label="Solicitante"
                                size="small"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormInput
                                ref={ sedeRef }
                                variant="outlined"
                                name="sede"
                                label="Faturar em: *"
                                size="small"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormSelect
                                name="delivery"
                                label="Local Entrega *"
                                size="small"
                                fullWidth
                                initialValue={ getDeliveryLocationsValue() }
                                options={ OCFormDefaultValue.localeDelivery.find(item => item.branch === userData.selectedBranch?.id)?.itens || [] }
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormAutoComplete
                                ref={ paymentConditionRef }
                                name="condicao_pagamento"
                                label="Condição de Pagamento *"
                                options={ paymentOptions }
                                loading={ isLoadingPaymentConditions }
                            />
                        </Grid>
                        { (selectedOcType !== "1" && selectedOcType) && (
                            <Grid item xs={12} md={12}>
                                <FormInput
                                    variant="outlined"
                                    name="obs_tipo_pagto"
                                    label="Informe o motivo desta solicitação*"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormCard>
                <FormCard title="Fornecedor">
                    <Grid container>
                        <Grid item xs={12} md={3} style={{ position: "relative"}}>
                            <FormInput
                                ref={ cnpjRef }
                                variant="outlined"
                                name="cnpj"
                                label={`CNPJ/CPF *`}
                                type="text"
                                size="small"
                                fullWidth
                                disabled
                                inputProps={{ maxLength: 18 }}
                            />
                            <CNPJActions>
                                <SearchSupplierButton type="button" onClick={() => setOpenSuppliersDialog(true)}>
                                    <SearchIcon/>
                                </SearchSupplierButton>
                            </CNPJActions>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormInput
                                ref={ codRef }
                                variant="outlined"
                                name="cod"
                                label={`Código *`}
                                type="text"
                                size="small"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <FormInput
                                ref={ lojaRef }
                                variant="outlined"
                                name="loja"
                                label={`Loja *`}
                                type="text"
                                size="small"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormInput
                                ref={reasonRef}
                                variant="outlined"
                                name="razao"
                                label={`Razão Social *`}
                                type="text"
                                size="small"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <FormInput
                                ref={nameRef}
                                variant="outlined"
                                name="nome"
                                label={`Nome Fantasia *`}
                                type="text"
                                size="small"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormInput
                                ref={subRef}
                                variant="outlined"
                                name="ie"
                                label="Inscrição Estadual"
                                type="text"
                                size="small"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormInput
                                ref={emailRef}
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                type="text"
                                size="small"
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                </FormCard>
                <FormCard title="Produtos">
                    <ProductsSelection>
                        <DataTable
                            columns={ columns }
                            rows={ productsList }
                            paginationType="disabled"
                            disableCheckboxSelection
                        />
                        <AddProductButton onSelect={() => setOpenProductsDialog(true)} />
                        {productsList.length > 0 &&
                            <p style={{ float: "right" }}><b>Total:</b> { totalProducts }</p>
                        }
                    </ProductsSelection>
                    <FormInput
                        variant="outlined"
                        name="observation"
                        label={`Descreva o item ou o serviço e finalidade * (Caracters proibídos: ', ", \\, /)`}
                        type="text"
                        size="small"
                        fullWidth
                        multiline
                        maxRows={4}
                    />
                </FormCard>
                { consistInBudgetsRules && (
                    <FormCard title="Orçamentos">
                        <Grid container>
                            <Grid 
                                item 
                                xs={12} 
                                md={6}
                                display="flex"
                                alignItems="center"
                            >
                                <div style={{ flex: 1 }}>
                                    <FormInput
                                        variant="outlined"
                                        name="valor_orcamento2"
                                        label="Orçamento 2"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        value={ budgets.valor_orcamento2 }
                                        onChange={ (event: any) => handleBudgetChanges(
                                            event.target.value?.replace(/[^0-9., ]+/g, ''),
                                            "valor_orcamento2"
                                        ) }
                                        onBlur={(event: any) => {
                                            handleBudgetOnBlur(
                                                event.target.value,
                                                "valor_orcamento2"
                                            )
                                        }}
                                    />
                                </div>
                                <OptionsButtons
                                    title="Anexar Orçamento"
                                    type="button"
                                    action="attach"
                                    icon={ AttachFile }
                                    onClick={() => {
                                        setSelectedAttachBudget("budget2")
                                        setOpenUploadFileModal(true)
                                    }}
                                />
                            </Grid>
                            <Grid 
                                item 
                                xs={12} 
                                md={6}
                                display="flex"
                                alignItems="center"
                            >
                                <div style={{ flex: 1 }}>
                                    <FormInput
                                        variant="outlined"
                                        name="valor_orcamento3"
                                        label="Orçamento 3"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        value={ budgets.valor_orcamento3 }
                                        onChange={ (event: any) => handleBudgetChanges(
                                            event.target.value?.replace(/[^0-9., ]+/g, ''),
                                            "valor_orcamento3"
                                        ) }
                                        onBlur={(event: any) => {
                                            handleBudgetOnBlur(
                                                event.target.value,
                                                "valor_orcamento3"
                                            )
                                        }}
                                    />
                                </div>
                                <OptionsButtons
                                    title="Anexar Orçamento"
                                    type="button"
                                    action="attach"
                                    icon={ AttachFile }
                                    onClick={() => {
                                        setSelectedAttachBudget("budget3")
                                        setOpenUploadFileModal(true)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormInput
                                    variant="outlined"
                                    name="saving2"
                                    label="Saving 2"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    disabled
                                    value={ savings.saving2 }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormInput
                                    variant="outlined"
                                    name="saving3"
                                    label="Saving 3"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    disabled
                                    value={ savings.saving3 }
                                />
                            </Grid>
                        </Grid>
                    </FormCard>
                )}
                <FormActions>
                    <Button 
                        type="button"
                        size='large'
                        variant="text"
                        onClick={() => navigate("/buy-orders/")}
                    >
                        Fechar
                    </Button>
                    <Button 
                        type="submit" 
                        variant="contained"
                        color="primary"
                        size='large'
                    >
                        { isLoadingSubmit ? <CircularProgress color="inherit" size={ 26 } /> : "Gravar" }
                    </Button>
                </FormActions>
            </Form>
        </Container>
        <SelectProductModal 
            open={ openProductsDialog }
            onSelect={ onSelectProduct } 
            onClose={ () => setOpenProductsDialog(false) } 
        />
        <SelectSupplierModal 
            open={ openSuppliersDialog }
            initialSearch={initialSearchSupplier} 
            onSelect={ onSelectSupplier } 
            onClose={ () => {
                setInitialSearchSupplier("")
                setOpenSuppliersDialog(false)
            }}
        />
        <AttachDialog 
            open={ openUploadFileModal }
            onUpload={ handleAttachBudget }
            fullWidth
            maxWidth="sm"
            disableEscapeKeyDown
            onClose={( _, reason) => {
                if(reason !== "backdropClick"){
                    setSelectedAttachBudget(null)
                    setOpenUploadFileModal(false)
                }
            }}
        />
        </>
    )
}