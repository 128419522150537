import { useState, useCallback, useRef } from "react"
import { FormHandles } from "@unform/core"
import { Form } from "@unform/web"
import { toast } from "react-toastify"

import { SupplierFormDefaultValue } from "../../../../../data/formValues"

import { useSupplier } from "../../../../../hooks/supplier"

import { getErrorMessage } from "../../../../../utils/validations/getErrorMessage"

import FiltersForm from "../../../../../components/FiltersForm"
import FormInput, { FormInputRef } from "../../../../../components/Form/FormInput"
import FormSelect, { FormSelectRef } from "../../../../../components/Form/FormSelect"


import { FilterIcon } from "../../../../../components/Misc/Icons/FilterIcon"
import { Fade, Grid, Tooltip } from "@mui/material"

import { ActionButton, FilterContainer, FilterPosition } from "./style"

interface FilterParams{
    status: string
    search: string
}

interface FilterSupplierProps{
    onStartFilter?: () => void
    onFinishFilter?: () => void
}

export const FilterSupplier: React.FC<FilterSupplierProps> = ({ onFinishFilter, onStartFilter }) => {
    const { getSuppliers } = useSupplier()

    const formRef = useRef<FormHandles>(null)

    const searchInputRef = useRef<FormInputRef>(null)
    const statusInputRef = useRef<FormSelectRef>(null)

    const [openFilter, setOpenFilter] = useState<boolean>(false)

    const filterSuppliers = useCallback(async(data: FilterParams) => {        
        try{
            if(onStartFilter){
                onStartFilter()
            }

            await getSuppliers({
                pesquisa: data.search,
                status:data.status 
            })

            setOpenFilter(false)
            
        } catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            if(onFinishFilter){
                onFinishFilter()
            }
        }
    },[getSuppliers, onFinishFilter, onStartFilter])

    const cleanerFilters = useCallback(async() => {
        try {

            statusInputRef.current?.changeValue("")
            searchInputRef.current?.changeValue("")

            if(onStartFilter){
                onStartFilter()
            }

            await getSuppliers()
        } catch (error: any) {
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            if(onFinishFilter){
                onFinishFilter()
            }
        }
    },[getSuppliers, onFinishFilter, onStartFilter])  

    return(
        <>
        <FilterContainer>
            <Tooltip title="Filtrar">
                <ActionButton onClick={() => setOpenFilter(prev => !prev)}>
                    <FilterIcon />
                </ActionButton>
            </Tooltip>
        </FilterContainer>
        <Fade in={ openFilter }>
            <FilterPosition>
                <FiltersForm
                    onClean={ cleanerFilters }
                    onSubmit={() => formRef.current?.submitForm() }
                    onClose={() => setOpenFilter(false)}
                >
                    <Form ref={ formRef } onSubmit={ filterSuppliers }>
                        <Grid container spacing={2}>
                            <Grid item xs={ 12 } md={ 4 }>
                                <FormSelect 
                                    name="status"
                                    label="Status"
                                    fullWidth
                                    size="small"
                                    options={ SupplierFormDefaultValue.status }
                                />
                            </Grid>
                            <Grid item xs={ 12 } md={ 8 }>
                                <FormInput 
                                    name="search"
                                    label="Código, Nome, CNPJ..."
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </FiltersForm>
            </FilterPosition>
        </Fade>
        </>
    )
}