import React from 'react'
import { useState, useImperativeHandle, forwardRef } from 'react'
import { toast } from 'react-toastify'

import { getErrorMessage } from '../../../../../utils/validations/getErrorMessage'

import { 
    Dialog,
    DialogActions, 
    DialogProps, 
    DialogContent,
    Button,
    TextField,
    CircularProgress
} from '@mui/material'

import { Close } from '@mui/icons-material'

import { DialogTitleCustom } from '../../../../../styles/globalStyles'

export interface FinishRequestModalRef {
    isOpen: boolean
    openDialog: () => void
    closeDialog: () => void
}

interface FinishRequestModalProps extends DialogProps {
    selectedOrderId?: string
    onFinish: (justify: string) => Promise<void>
}

const FinishRequestModal: React.ForwardRefRenderFunction<
    FinishRequestModalRef, 
    FinishRequestModalProps
> = ({ 
    open: initialOpen,
    onFinish,
    selectedOrderId,
    ...rest
}, ref) => {

    const [open, setOpen] = useState<boolean>(initialOpen)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [justify, setJustify] = useState<string>("")
    const [$isErrored, set$isErrored] = useState<boolean>(false)

    const onFinishRequest = async() => {
        if(justify.length <= 0){
            return set$isErrored(true)
        }
        
        set$isErrored(false)
        setIsLoading(true)

        try{
            await onFinish(justify)
            
            setOpen(false)
        }catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        }

        setIsLoading(false)
    }

    useImperativeHandle(ref, () => ({
        isOpen: open,
        openDialog: () => setOpen(true),
        closeDialog: () => {
            setOpen(false)
            setJustify("")
            setIsLoading(false)
        }
    }))

    return(
        <Dialog
            { ...rest }
            open={ open }
            fullWidth
            maxWidth="sm"
            onClose={ () => setOpen(false) }
        >
            <DialogTitleCustom>
                Finalizar solicitação: { selectedOrderId }
                <button disabled={ isLoading } onClick={() => setOpen(false)}><Close/></button>
            </DialogTitleCustom>
            <DialogContent>
                <br/>
                <TextField
                    label="Justificativa *"
                    type="text"
                    size="small"
                    fullWidth
                    maxRows={4}
                    error={ $isErrored }
                    value={ justify }
                    onChange={ event => {
                        set$isErrored(false)
                        setJustify(event.target.value)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="success"
                    variant="contained"
                    disabled={ isLoading }
                    style={{ width: "120px" }}
                    onClick={ () => {
                        if(!isLoading){
                            onFinishRequest()
                        }
                    }}
                >
                    { isLoading ? <CircularProgress color="inherit" size={ 26 } /> : "Finalizar" }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default forwardRef(FinishRequestModal)